.outer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.contents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.form-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 66%;
    border-radius: 10px;
    padding: 20px;
}

.myForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    border: 0.5px solid #00000046;
    background-color: white;
    padding: 10px 20px;
    box-shadow: 2px 2px 2px 0px #00000027;
    box-sizing: border-box;
}

.input-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .input{
    border: 1px solid #000;
    padding: 8px;
    width: 100%;
    height: 35px;
    box-sizing: border-box;
    background-color: #f7f8f9;
} 

.input-container label{
    margin-bottom: 5px;
    font-weight: bold;
}

.date-el {
    border: 1px solid #000;
    padding: 8px;
    width: 100%;
    height: 35px;
    box-sizing: border-box;
    background-color: #f7f8f9;
}


.result-data{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    height: 90%;
    border: 0.5px solid #00000046;
    background-color: white;
    box-shadow: 2px 2px 2px 0px #00000027;
    box-sizing: border-box;
    overflow-y: auto;
    padding-top: 20px;
}


.react-datepicker-wrapper{
    width: 100%;

}


.react-datepicker-popper {
    z-index: 1000;
}


.result-el-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}


.custom-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .custom-alert {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .custom-alert button {
    margin: 10px;
  }
  

  .metadata{
    background-color: white;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    height: 65%;
    
  }

  .result-header{
    position: fixed;
    top: 15px;
    right:40px;
    width: 15%;
  }